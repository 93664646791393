import { render, staticRenderFns } from "./pricehelp.vue?vue&type=template&id=02a66209&"
import script from "./pricehelp.vue?vue&type=script&lang=js&"
export * from "./pricehelp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('02a66209')) {
      api.createRecord('02a66209', component.options)
    } else {
      api.reload('02a66209', component.options)
    }
    module.hot.accept("./pricehelp.vue?vue&type=template&id=02a66209&", function () {
      api.rerender('02a66209', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8ua9UtVB28MJI/JS/pricehelp.vue"
export default component.exports